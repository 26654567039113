import React from 'react';
import { graphql, useStaticQuery } from 'gatsby';
import { Helmet } from 'react-helmet-async';

import * as Constants from '~/src/constants';

const Layout: React.FC = ({ children }) => {
  const data = useStaticQuery<GatsbyTypes.LayoutQuery>(graphql`
    query Layout {
      site {
        siteMetadata {
          siteUrl
          title
          shortName
          description
        }
      }
      opengraphImage: file(relativePath: { eq: "opengraph-image.png" }) {
        publicURL
      }
    }
  `);

  return (
    <>
      <Helmet>
        <html prefix="og: http://ogp.me/ns#" />
        {data.site?.siteMetadata?.siteUrl && data.opengraphImage && (
          <meta property="og:image" content={data.site.siteMetadata.siteUrl + data.opengraphImage.publicURL}/>
        )}
        <meta name="twitter:app:id:iphone" content={Constants.APPLE_APP_ID} />
        {data.site?.siteMetadata?.shortName && (
          <meta name="twitter:app:name:iphone" content={data.site.siteMetadata.shortName} />
        )}
        <meta name="twitter:app:id:ipad" content={Constants.APPLE_APP_ID} />
        {data.site?.siteMetadata?.shortName && (
          <meta name="twitter:app:name:ipad" content={data.site.siteMetadata.shortName} />
        )}
        <meta name="twitter:app:id:googleplay" content={Constants.GOOGLE_PLAY_APP_ID} />
        {data.site?.siteMetadata?.shortName && (
          <meta name="twitter:app:name:googleplay" content={data.site.siteMetadata.shortName} />
        )}
      </Helmet>
      {children}
    </>
  );
};

export default Layout;
