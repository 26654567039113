import React from 'react';

import Layout from '~/src/components/Layout';
import IndexPage from '~/src/components/IndexPage';

const GatsbyIndexPage: React.FC = () => {
  return (
    <Layout>
      <IndexPage/>
    </Layout>
  );
};

export default GatsbyIndexPage;
