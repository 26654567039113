export const APPLE_APP_ID = '1153389718';
export const GOOGLE_PLAY_APP_ID = 'com.devsisters.SolitaireDeckedOut';

export const DOWNLOAD_LINK_APP_STORE = 'https://apps.apple.com/app/solitaire-decked-out-ad-free/id1153389718';
export const DOWNLOAD_LINK_PLAY_STORE = 'https://play.google.com/store/apps/details?id=com.devsisters.SolitaireDeckedOut';

export const FACEBOOK_PAGE_LINK = 'https://www.facebook.com/SolitaireDeckedOut/';

export const CUSTOMER_SUPPORT_LINK = 'https://cs.devsisters.com/solitaire-decked-out';
export const PRIVACY_POLICY_LINK = 'https://policy.devsisters.com/privacy';
export const TERMS_OF_SERVICE_LINK = 'https://policy.devsisters.com/terms-of-service';
