import React from 'react';
import Slider from 'react-slick';
import { graphql, useStaticQuery, Link } from 'gatsby';
import Img from 'gatsby-image';
import { OutboundLink } from 'gatsby-plugin-google-gtag';
import { DevsistersCi } from '@devsisters/ui-common/lib/identity';

import { ReactComponent as AppStoreIcon } from '~/src/images/appstore.svg';
import { ReactComponent as PlayStoreIcon } from '~/src/images/playstore.svg';

import * as Constants from '~/src/constants';

import * as styles from './IndexPage.treat';

import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';

const IndexPage: React.FC = () => {
  const data = useStaticQuery<GatsbyTypes.IndexPageQuery>(graphql`
    query IndexPage {
      topTitle: file(relativePath: { eq: "solitaire-title.png" }) {
        childImageSharp {
          fluid(maxWidth: 546) {
            ...GatsbyImageSharpFluid_withWebp_noBase64
          }
        }
      }
      downloadButton: file(relativePath: { eq: "download-button.png" }) {
        childImageSharp {
          fluid(maxWidth: 570) {
            ...GatsbyImageSharpFluid_withWebp_noBase64
          }
        }
      }
      facebookButton: file(relativePath: { eq: "facebook-button.png" }) {
        childImageSharp {
          fluid(maxWidth: 570) {
            ...GatsbyImageSharpFluid_withWebp_noBase64
          }
        }
      }
      appIcon: file(relativePath: { eq: "icon-stroke.png" }) {
        childImageSharp {
          fluid(maxWidth: 200) {
            ...GatsbyImageSharpFluid_withWebp_noBase64
          }
        }
      }
      slide1: file(relativePath: { eq: "screenshot-slide-1.png" }) {
        childImageSharp {
          fluid(maxWidth: 300) {
            ...GatsbyImageSharpFluid_withWebp_noBase64
          }
        }
      }
      slide2: file(relativePath: { eq: "screenshot-slide-2.png" }) {
        childImageSharp {
          fluid(maxWidth: 300) {
            ...GatsbyImageSharpFluid_withWebp_noBase64
          }
        }
      }
      slide3: file(relativePath: { eq: "screenshot-slide-3.png" }) {
        childImageSharp {
          fluid(maxWidth: 300) {
            ...GatsbyImageSharpFluid_withWebp_noBase64
          }
        }
      }
    }
  `);

  return (
    <>
      <header>
        <h1 className={styles.topTitleContainer}>
          <Img
            className={styles.topTitleImage}
            fluid={data.topTitle?.childImageSharp?.fluid}
            loading="eager"
          />
        </h1>
        <section className={styles.playButtonContainer}>
          <Link to="/game/" className={styles.imageLink}>
            <Img
              className={styles.playButtonImage}
              fluid={data.downloadButton?.childImageSharp?.fluid}
              loading="eager"
            />
          </Link>
        </section>
      </header>
      <main>
        <section className={styles.videoSection}>
          <div className={styles.videoContainer}>
          <iframe
            src="https://www.youtube.com/embed/__4q2QWRZAA?rel=0"
            className={styles.videoFrame}
            frameBorder="0"
            allowFullScreen
          />
          </div>
        </section>
        <section className={styles.appInfoContainer}>
          <Img
            className={styles.appIcon}
            fluid={data.appIcon?.childImageSharp?.fluid}
            loading="eager"
          />
          <OutboundLink href={Constants.DOWNLOAD_LINK_APP_STORE} className={styles.appStoreLink}>
            <AppStoreIcon />
          </OutboundLink>
          <OutboundLink href={Constants.DOWNLOAD_LINK_PLAY_STORE} className={styles.playStoreLink}>
            <PlayStoreIcon />
          </OutboundLink>
        </section>
        <section>
          <h2 className={styles.featureSectionTitle}>
            Classic Solitaire with Fun Theme Decks
          </h2>
          <div className={styles.featureCarouselContainer}>
            <div className={styles.featureCarouselItem}>
              <Img
                fluid={data.slide1?.childImageSharp?.fluid}
              />
            </div>
            <div className={styles.featureCarouselItem}>
              <Img
                fluid={data.slide2?.childImageSharp?.fluid}
              />
            </div>
            <div className={styles.featureCarouselItem}>
              <Img
                fluid={data.slide3?.childImageSharp?.fluid}
              />
            </div>
          </div>
          <Slider
            className={styles.featureCarouselContainerMobile}
            dots
            infinite
            autoplay
            arrows={false}
            centerMode
            centerPadding='50px'
            autoplaySpeed={2000}
          >
            <div>
              <Img
                className={styles.featureCarouselItemMobile}
                fluid={data.slide1?.childImageSharp?.fluid}
              />
            </div>
            <div>
              <Img
                className={styles.featureCarouselItemMobile}
                fluid={data.slide2?.childImageSharp?.fluid}
              />
            </div>
            <div>
              <Img
                className={styles.featureCarouselItemMobile}
                fluid={data.slide3?.childImageSharp?.fluid}
              />
            </div>
          </Slider>
        </section>
      </main>
      <footer>
        <div className={styles.footerMainContainer}>
          <div className={styles.footerMainItem}>
            <Link to="/game/" className={styles.imageLink}>
              <Img
                fluid={data.downloadButton?.childImageSharp?.fluid}
                loading="eager"
              />
            </Link>
          </div>
          <div className={styles.footerMainItem}>
            <OutboundLink href={Constants.FACEBOOK_PAGE_LINK} className={styles.imageLink}>
              <Img
                fluid={data.facebookButton?.childImageSharp?.fluid}
                loading="eager"
              />
            </OutboundLink>
          </div>
        </div>
        <div className={styles.footerAboutContainer}>
          <OutboundLink
            className={styles.footerAboutItem}
            href={Constants.CUSTOMER_SUPPORT_LINK}
          >
            {'Customer\nSupport'}
          </OutboundLink>
          <OutboundLink
            className={styles.footerAboutItem}
            href={Constants.PRIVACY_POLICY_LINK}
          >
            {'Privacy\nPolicy'}
          </OutboundLink>
          <OutboundLink
            className={styles.footerAboutItem}
            href={Constants.TERMS_OF_SERVICE_LINK}
          >
            {'Terms of\nService'}
          </OutboundLink>
        </div>
        <div className={styles.footerLogoContainer}>
          <DevsistersCi/>
        </div>
      </footer>
    </>
  );
};

export default IndexPage;
