import "!../../node_modules/mini-css-extract-plugin/dist/loader.js?{}!css-loader?{\"modules\":false,\"url\":false}!./IndexPage.treat.2I5hOF2.css";
export var topTitleContainer = 'yQFcp';
export var topTitleImage = 'QS6ht';
export var playButtonContainer = '_2wSPr';
export var playButtonImage = 'WUsp9';
export var imageLink = '_2dNzN';
export var videoSection = '_3ZsnP';
export var videoContainer = '_2pLoH';
export var videoFrame = '_3oIaU';
export var appInfoContainer = '_2d8OW';
export var appIcon = 'Sc2Km';
export var appStoreLink = '_3s_iz';
export var playStoreLink = '_1oZQX';
export var featureSectionTitle = '_1UWCV';
export var featureCarouselContainer = '_1cTMV';
export var featureCarouselContainerMobile = '_7uap3';
export var featureCarouselItem = '_3QUBH';
export var featureCarouselItemMobile = '_3J-Ac';
export var footerMainContainer = '_3al3d';
export var footerMainItem = '_2Gtup';
export var footerAboutContainer = '_2cg_Z';
export var footerAboutItem = 'Gv3f6';
export var footerLogoContainer = '_1DK0P';